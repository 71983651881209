<script>
import Page from "./page.vue";
export default {
  name: "NotFound",
  extends: Page,
  data() {
    return {
      title: "404: NOT FOUND",
      message: "Pagina non trovata.",
      action: this.go,
    };
  },
  methods: {
    go() {
      this.$router.go(-1);
    },
  },
};
</script>
