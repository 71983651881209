<template>
  <div class="wrapper">
    <div class="content">
      <h5>{{ title }}</h5>
      <p>
        {{ message }}
      </p>
      <b-button size="sm" variant="lisaweb" @click="action">OK</b-button>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style lang="scss" scoped>
.wrapper {
  background-color: lightgray;
  height: 100%;
  display: -webkit-flexbox;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  justify-content: center;
  .content {
    text-align: center;
    p {
      font-weight: bold;
      font-size: 20px;
      color: #88530e;
      padding: 20px;
      button {
        margin-top: 20px;
      }
    }
  }
}
</style>
